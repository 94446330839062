import Vue from 'vue'
import Vuex from 'vuex'
import {app} from '../firebase'
import router from '../router_home'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentLocale: 'en',
    currentDir: 'ltr',
    currentLang:'EN',
    posts:[],
    About:null,
    user:null
  },
  getters: {
    getCurrentLocale: (state) => state.currentLocale,
    Lang: (state) => state.currentLang,
    getCurrentDir: (state) => state.currentDir,
    getPosts:(state)=>state.posts,
    getUser:(state)=>state.user,
    getAbout:(state)=>state.About
    
  }
  ,
  actions: {
    loadPosts({commit}){
      app.database().ref('data/Posts').once('value')
      .then((data)=>{
        const dataPosts =[] ;
        const obj = data.val();

        for (let key in obj){
          dataPosts.push({"id":key,...obj[key]})
        }
        commit('setPosts',dataPosts);
      })
      .catch(
        (error)=>{
          console.log(error)
        }
      );
      
    },
    setLocale({commit,state}){
      if(localStorage.getItem('locale')){
        commit('changeLocale',localStorage.getItem('locale'))
      }
      else{
        
        localStorage.setItem('locale',state.currentLocale);
        commit('changeLocale',localStorage.getItem('locale'))
      }
    },
    signUserIn({commit},payload) {
      app.auth().signInWithEmailAndPassword(payload.email,payload.password)
      .then(user=>{
        
        const newUser = {
          uid:user.user.multiFactor.user.uid,
          email:user.user.multiFactor.user.email
        }
        
        commit('setUser',newUser)
      }).catch(error=>{
        console.log(error)
      });

    },
    autoSignIn({commit},payload){
      
      commit('setUser',{id:payload.uid,email:payload.email})
    },
    logOut({commit})
    {
      app.auth().signOut();
      commit('setUser',null);
      location.reload();
    }
  },
  mutations: {
    setUser(state,newUser){
      state.user = newUser;
      router.push("/dashboard/posts")
    },
    changeLocale(state, newLocale) {
      state.currentLocale = newLocale;
      state.currentDir = newLocale == 'ku' ? 'rtl' : 'ltr';
      state.currentLang = newLocale =='ku' ? 'KU' : newLocale =='kr'?'KR':'EN';
      localStorage.setItem('locale',state.currentLocale);
    },
    
    addPost(state, newPost){
      app.database().ref('data/Posts').push(newPost);
      location.reload();
      
    },
    deletePost(state, id){
      app.database().ref('data/Posts').child(id).remove();
      location.reload();
      
    },
    updatePost(state,data){
      console.log(data)
      app.database().ref('data/Posts').child(data.id).set({...data.data});
      location.reload();
      
    },
    updateAbout(state,data){
      console.log(data)
      app.database().ref('data/About').child(data.id).set({...data.data});
      location.reload();
      
    },
    setPosts(state,payload){
      state.posts=payload;
      
    }
  }
})
