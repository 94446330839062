// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import 'firebase/compat/database'
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcC13TMphvsUqLSGTDb_dCMWMPofbJZmQ",
  authDomain: "agora-vision-website-a78ae.firebaseapp.com",
  databaseURL: "https://agora-vision-website-a78ae-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "agora-vision-website-a78ae",
  storageBucket: "gs://agora-vision-website-a78ae.appspot.com",
  messagingSenderId: "324391541422",
  appId: "1:324391541422:web:304582d3c5aeffc61fab3f",
  measurementId: "G-K8KSENXNM8"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);


