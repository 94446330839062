import Vue from 'vue'
import VueRouter from 'vue-router'
import deng from '../views/deng.vue'
import voice from '../views/voice.vue'
import pertuk from '../views/pertuk.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/deng', component: deng  },
  { path: '/voice', component: voice},
  { path: '/pertuk', component: pertuk},
  { path: '/', component: Home },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})  

export default router
