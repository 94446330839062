<template>
  <div>
    <div
      id="notifications"
      class="shadow-lg rounded-md text-center"
      :class="{ notificationActive: notificationActive }"
    >
      <h1 class="text-xl m-0 text-agora-blue font-bold">Our Offers & News</h1>
      <hr class="bg-gray-100 my-3" />
      <ul>
        <li class="w-8/10 agora-green rounded-md bg py-5 text-gray-100">
          <a href="agoradeng.site/offers">Agora Deng 333 users offer!</a>
        </li>
      </ul>
    </div>
    <div id="branches" dir="ltr">
      <div class="container shadow-lg overflow-hidden">
        <div class="base">
          <div>
            <div
              class="text-sm px-3 text-gray-100 uppercase items-center justify-center pt-3"
            >
              <div class="flex justify-center">
                <a
                  class="block siteColor capitalize w-16 h-8 rounded-md text-center leading-loose transition cursor-pointer"
                  @click="switchLocale('en')"
                  :key="'en'"
                  style="font-family: 'Montserrat', sans-serif !important"
                  >{{ $t("Language.English") }}</a
                >
                <span
                  class="pt-1 siteColor"
                  style="
                    font-weight: 100;
                    font-family: 'Montserrat', sans-serif !important;
                  "
                  >|</span
                >
                <a
                  class="block siteColor pb-5 mb-2 w-16 h-8 rounded-md text-center mx-2 leading-loose transition cursor-pointer"
                  @click="switchLocale('ku')"
                  :key="'ku'"
                  style="font-family: 'Speda' !important"
                  >{{ $t("Language.Kurdish") }}</a
                >
                <span
                  class="pt-1 siteColor"
                  style="
                    font-weight: 100;
                    font-family: 'Montserrat', sans-serif !important;
                  "
                  >|</span
                >
                <a
                  class="block siteColor capitalize w-16 h-8 rounded-md text-center mx-2 leading-loose transition cursor-pointer"
                  @click="switchLocale('kr')"
                  :key="'kr'"
                  style="font-family: 'Montserrat', sans-serif !important"
                  >{{ $t("Language.kirmanji") }}</a
                >
              </div>
            </div>
            <hr class="border-gray-200 shadow-lg mb-2" />
          </div>
          <div
            class="text-center pb-3 lg:py-5 font-bold pulseMe"
            style="color: #3a84b2"
          >
            <a href="https://www.agoravision.site/offers" target="_blank">
              {{ $t("offers") }}</a
            >
          </div>

          <div class="grid justify-center">
            <div class="hidden flex justify-center"></div>

            <div class="grid grid-rows-3 gap-3 mt-1">
              
              <router-link to="/deng">
                <div class="w-60 h-16 rounded-2xl shadow-lg deng rect">
                  <img
                    src="/Images/deng.png"
                    alt="Agora Vision Logo"
                    style="width: 50px; height: 50px"
                  />
                  <div class="grid">
                    <span class="title text-agora-green">{{ $t("branches.agoradengDWN") }}</span>
                    <span class="text-xs">{{
                      $t("branches.agoradengtype")
                    }}</span>
                  </div>
                </div>
              </router-link>
              <!-- <a target="_blank" href="https://agorawarger.site">
                <div class="w-60 h-16 rounded-2xl shadow-lg warger rect">
                  <img
                    src="/Images/warger.png"
                    alt="Agora Vision Logo"
                    style="width: 50px; height: 50px"
                  />
                  <div class="grid">
                    <span class="title">{{ $t("branches.agorawarger") }}</span>
                    <span class="text-xs">{{
                      $t("branches.agorawargertype")
                    }}</span>
                  </div>
                </div>
              </a> -->
              
              <router-link to="/pertuk"
              >
                <div class="w-60 h-16 rounded-2xl shadow-lg voice rect">
                  <img
                    src="/Images/pertuk.png"
                    alt="Agora Pertuk Logo"
                    style="width: 50px; height: 50px"
                  />
                  <div class="grid">
                    <span class="title text-agora-gold">{{ $t("branches.agorapertukVST") }}</span>
                    <span class="text-xs">{{
                      $t("branches.agorapertuktype")
                    }}</span>
                  </div>
                </div>
              </router-link>
              <a
                target="_blank"
                href="https://www.agoravoice.site"
              >
                <div class="w-60 h-16 rounded-2xl shadow-lg voice rect">
                  <img
                    src="/Images/voice.png"
                    alt="Agora Voice Logo"
                    style="width: 50px; height: 50px"
                  />
                  <div class="grid">
                    <span class="title text-agora-red">{{ $t("branches.agoravoiceVST") }}</span>
                    <span class="text-xs">{{
                      $t("branches.agoravoicetype")
                    }}</span>
                  </div>
                </div>
              </a>
              <a target="_blank" href="https://agoravision.site">
                <div class="w-60 h-16 rounded-2xl shadow-lg vision rect">
                  <!-- <div class="bg"></div> -->
                  <img
                    src="/Images/logo.png"
                    alt="Agora Vision Logo"
                    style="width: 50px; height: 50px"
                  />
                  <div class="grid">
                    <span class="title">{{ $t("branches.agoravisionVST") }}</span>
                    <span class="text-xs">{{
                      $t("branches.agoravisiontype")
                    }}</span>
                  </div>
                </div>
              </a>
              
            </div>
          </div>

          <div class="text-gray-500">
            <br>
            <div
              class="hidden text-center py-2 appear py-3 lg:my-3 font-bold"
              style="font-size: 12px"
            >
              <!-- {{ $t("direction") }} --><span>{{ $t("slogan") }}</span>
            </div>
            <div class="phone">
              <hr class="border-gray-200 shadow-lg mb-2" />
              <span
                class="text-md flex justify-around py-1 text-xs items-center"
                style="font-family: montserrat"
              >
                <a
                  :class="getCurrentDir == 'rtl' ? 'text-azher' : ''"
                  :dir="getCurrentDir"
                  style="font-family: 'Montserrat', sans-serif !important"
                  href="tel:009647702000545"
                  >{{ $t("ContactForm.PhoneOne") }}</a
                >
                <span class="pb-1">|</span>
                <a
                  :class="getCurrentDir == 'rtl' ? 'text-azher' : ''"
                  :dir="getCurrentDir"
                  style="font-family: 'Montserrat', sans-serif !important"
                  href="tel:009647502000545"
                  >{{ $t("ContactForm.PhoneTwo") }}</a
                >
              </span>
            </div>
            <div class="social flex justify-around text-lg py-1">
              <a href="https://www.facebook.com/agoravisioncom" target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/agoravision/"
                target="_blank"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a
                href="https://www.instagram.com/agoravisioncom/"
                target="_blank"
              >
                <i class="fab fa-instagram"></i>
              </a>

              <a
                href="https://www.youtube.com/channel/UCcGXMcvYue1apphdDW4jtZA/featured"
                target="_blank"
              >
                <i class="fab fa-youtube"></i>
              </a>
              <a
                href="https://web.telegram.org/#/im?p=@agoravision"
                target="_blank"
              >
                <i class="fab fa-telegram"></i>
              </a>
            </div>
            <div class="text-xs text-center my-1 pt-2 hidden">
              <span
                class="font-light leading-5"
                v-html="$t('ContactForm.Address')"
              ></span>
            </div>
          </div>
        </div>
        <hr class="hidden" />
        <div class="py-4 flex items-center justify-center bg-gray-100 hidden">
          <div
            style="background-color: #3a84b2"
            class="relative rounded-full w-12 h-12 shadow-2xl shadow-inner"
          >
            <i
              class="absolute fa fa-book-open text-gray-100"
              style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
            ></i>
            <div
              class="bg-white rounded-full w-12 h-12 shadow-2xl shadow-inner animate-ping"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["getCurrentLocale", "getCurrentDir", "getUser"]),
  methods: {
    saveLocale() {
      sessionStorage.setItem("locale", this.$store.getters.getCurrentLocale);
    },
    switchLocale(newLocale) {
      this.$store.commit("changeLocale", newLocale);
      this.$i18n.locale = newLocale;
      this.toggled = false;
      document.querySelector("body").removeAttribute("class");
      document.querySelector("body").classList.add(this.getCurrentDir);
      setTimeout(() => {}, 300);
    },
  },
  mounted() {
    this.switchLocale("ku");
  },
};
</script>

<style>
</style>