<template>
  <div id="loader">
      <img src="/Images/loading.gif" alt="Loading" style="width:90px">
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>