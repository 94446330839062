<template>
  <div :class="getCurrentDir" :dir="getCurrentDir">
    <div id="branches" class="deng" dir="ltr">
      <div class="bgImage"></div>
      <div class="container deng shadow-lg overflow-hidden">
        <div class="base">
          <div>
            <div
              class="text-sm px-3 text-gray-100 uppercase items-center justify-center pt-3"
            >
              <div class="flex justify-between">
                <a
                  class="block text-agora-green capitalize h-8 rounded-md text-center leading-loose transition cursor-pointer"
                  @click="switchLocale('kr')"
                  :key="'kr'"
                  style="font-family: 'Montserrat', sans-serif !important"
                  >{{ $t("Language.kirmanji") }}</a
                >

                <a
                  class="block text-agora-green capitalize h-8 rounded-md text-center leading-loose transition cursor-pointer"
                  @click="switchLocale('ku')"
                  :key="'ku'"
                  style="font-family: 'Speda' !important"
                  >{{ $t("Language.Kurdish") }}</a
                >
              </div>
            </div>
            <hr class="border-gray-200 shadow-lg mb-2" />
          </div>
          <div class="text-center pb-3 lg:py-5" style="color: #3a84b2">
            <div>
              <h1 class="text-md m-0 text-agora-green">
                {{ $t("App.Download") }}
              </h1>
            </div>
          </div>

          <div class="grid justify-center gap-5">
            <a
              target="_blank"
              class="block download"
              href="https://play.google.com/store/apps/details?id=site.agoravision.agoradeng"
            >
              <img
                src="/Images/androidDownload.png"
                class="w-full h-auto"
                alt="Android Download"
              />
            </a>

            <a
              target="_blank"
              class="block download"
              href="https://apps.apple.com/us/app/agora-deng-%D8%A6%D8%A7%DA%AF%DB%86%D8%B1%D8%A7-%D8%AF%DB%95%D9%86%DA%AF/id1622027385"
            >
              <img
                src="/Images/iOSDownload.png"
                class="w-full h-auto"
                alt="iOS Download"
              />
            </a>
            <a href="https://agoradeng.site" target="_blank" class="web">
              <div class="w-full h-16 rounded-2xl shadow-lg deng rect">
                <img
                  src="/Images/deng.png"
                  alt="Agora Vision Logo"
                  style="width: 50px; height: 50px"
                />
                <div class="grid">
                  <span class="title text-agora-green">{{ $t("branches.agoradeng") }}</span>
                  <span class="text-xs">{{
                    $t("visitwebsite")
                  }}</span>
                </div>
              </div>
            </a>
            <!-- <router-link to="/" class="goBack block shadow-lg bg-gray-100 w-14 h-14 flex items-center just-fy-center rounded-full mx-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1"
                id="Layer_1"
                viewBox="0 0 100 100"
                width="48px" height="48px"
                stroke="#3e9f9d"
                fill="#3e9f9d"
                class="mx-auto"
              >
                <title />
                <path
                  d="M50,95A45.01732,45.01732,0,0,0,92.337,65.28491a1,1,0,0,0-1.88111-.6792l-.00006-.00006A43.13993,43.13993,0,1,1,92.97467,49H48.41418l8.29291-8.29291a1,1,0,0,0-1.41418-1.41418l-10.00006,10-.00391.00586a.99779.99779,0,0,0,0,1.4024l.00391.00592,10,10a1,1,0,1,0,1.4143-1.41418h-.00006L48.41418,51H94a1,1,0,0,0,1-1A45,45,0,1,0,50,95Z"
                />
                </svg>
            </router-link> -->
          </div>

          <div class="text-gray-500">
            <div
              class="text-agora-green text-center py-2 py-3 lg:my-3"
              style="font-size: 12px"
            >
              <!-- {{ $t("direction") }} --><a :href="getCurrentDir=='rtl'?'https://www.agoradeng.site/how-to':'https://www.agoradeng.site/kmr/gaven-ji-bo-bikaranina'" target="_blank"><span>{{ $t("guideLink") }}</span></a>
            </div>
            <div class="phone">
              <hr class="border-gray-200 shadow-lg mb-2" />
              <span
                class="text-md flex justify-around py-1 text-xs items-center"
                style="font-family: montserrat"
              >
                <a
                  :class="getCurrentDir == 'rtl' ? 'text-azher' : ''"
                  :dir="getCurrentDir"
                  style="font-family: 'Montserrat', sans-serif !important"
                  href="tel:009647702000545"
                  >{{ $t("ContactForm.PhoneOne") }}</a
                >
                <span class="pb-1">|</span>
                <a
                  :class="getCurrentDir == 'rtl' ? 'text-azher' : ''"
                  :dir="getCurrentDir"
                  style="font-family: 'Montserrat', sans-serif !important"
                  href="tel:009647502000545"
                  >{{ $t("ContactForm.PhoneTwo") }}</a
                >
              </span>
            </div>
            <div class="social flex justify-around text-lg py-1">
              <a href="https://www.facebook.com/agoravisioncom" target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/agoravision/"
                target="_blank"
              >
                <i class="fab fa-linkedin-in"></i>
              </a>
              <a
                href="https://www.instagram.com/agoravisioncom/"
                target="_blank"
              >
                <i class="fab fa-instagram"></i>
              </a>

              <a href="https://www.youtube.com/@agoravisioncom" target="_blank">
                <i class="fab fa-youtube"></i>
              </a>
              <a
                href="https://web.telegram.org/#/im?p=@agoravision"
                target="_blank"
              >
                <i class="fab fa-telegram"></i>
              </a>
            </div>
            <div class="text-xs text-center my-1 pt-2 hidden">
              <span
                class="font-light leading-5"
                v-html="$t('ContactForm.Address')"
              ></span>
            </div>
          </div>
        </div>
        <hr class="hidden" />
        <div class="py-4 flex items-center justify-center bg-gray-100 hidden">
          <div
            style="background-color: #3a84b2"
            class="relative rounded-full w-12 h-12 shadow-2xl shadow-inner"
          >
            <i
              class="absolute fa fa-book-open text-gray-100"
              style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
            ></i>
            <div
              class="bg-white rounded-full w-12 h-12 shadow-2xl shadow-inner animate-ping"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
  
import { mapGetters } from "vuex";
export default {
 
  computed: mapGetters(["getCurrentLocale", "getCurrentDir", "getUser"]),
  title:'Agora Deng - ئاگۆرا دەنگ',
  methods: {
    saveLocale() {
      sessionStorage.setItem("locale", this.$store.getters.getCurrentLocale);
    },
    switchLocale(newLocale) {
      this.$store.commit("changeLocale", newLocale);
      this.$i18n.locale = newLocale;
      this.toggled = false;
      document.querySelector("body").removeAttribute("class");
      document.querySelector("body").classList.add(this.getCurrentDir);
      setTimeout(() => {}, 300);
    },
  },
  mounted() {
    setTimeout(() => {
      this.switchLocale("ku");
    }, 350);
  },
};
</script>
  
  <style>
</style>