<template>
  <div id="app" :class="getCurrentDir" :dir="getCurrentDir">
    <router-view>
      
    </router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["getCurrentLocale", "getCurrentDir", "getUser"]),
  methods: {
    saveLocale() {
      sessionStorage.setItem("locale", this.$store.getters.getCurrentLocale);
    },
    switchLocale(newLocale) {
      this.$store.commit("changeLocale", newLocale);
      this.$i18n.locale = newLocale;
      this.toggled = false;
      document.querySelector("body").removeAttribute("class");
      document.querySelector("body").classList.add(this.getCurrentDir);
      setTimeout(() => {}, 300);
    },
  },
  mounted() {
    this.switchLocale("ku");
  },
};
</script>

<style>
</style>